// ToggleSwitch.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../style/variables.js';

const ToggleSwitch = ({
  active = false,
  activeColor = color.tealBlue,
  inactiveColor = color.neutral[30],
  disable = false,
  onToggle = () => null,
  ...restProps
}) => (
  <SwitchWrapper
    data-element_id={restProps?.['data-element_id']}
    data-tracking_payload={restProps?.['data-tracking_payload']}
    onClick={() => {
      if (disable) return;
      onToggle();
    }}
    $active={disable ? false : active}
    $disable={disable}
    $activeColor={activeColor}
    $inactiveColor={inactiveColor}
  >
    <Slider
      $active={disable ? false : active}
      $disable={disable}
      $inactiveColor={inactiveColor}
    />
  </SwitchWrapper>
);

const SwitchWrapper = styled.div`
  position: relative;
  border: solid 1px
    ${({ $active, $activeColor, $inactiveColor }) =>
      $active ? $activeColor : $inactiveColor};
  border-radius: 52px;
  height: 30px;
  width: 52px;
  transition-duration: 0.3s;
  transition-property: background-color, border-color;
  cursor: pointer;
  background-color: ${({ $disable, $active, $activeColor, $inactiveColor }) =>
    $disable ? 'transparent' : $active ? $activeColor : $inactiveColor};
`;

const Slider = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ $active }) => ($active ? 25 : 1)}px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  transform: translateY(-50%);
  transition-duration: 0.3s;
  transition-property: left;
  background-color: ${({ $disable }) => ($disable ? '#646464' : '#ffffff')};
`;

ToggleSwitch.propTypes = {
  active: PropTypes.bool,
  disable: PropTypes.bool,
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  onToggle: PropTypes.func,
};

export default ToggleSwitch;

// SortButton.jsx
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import ModalView from '../container/ModalView.js';

import UniversalContainer from '../component/UniversalContainer.jsx';

import { ModalId } from '../resource/mixpanel.js';
import { QueryType, OPTION_UNSET } from '../resource/feedConstants.js';
import getTranslationKey from '../resource/getTranslationKey.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import { color, textColor, breakpoint } from '../style/variables.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import TextEllipsis from '../style/TextEllipsis.js';
import media from '../style/media.js';

import SortIconSource from '../../img/ic_sorting.svg';
import SortIconBlueSource from '../../img/ic_sorting_blue.svg';

const MODAL_VIEW_STYLE = {
  position: 'static',
};

export const SortButton = ({
  defaultItemI18nKey = '',
  modalViewRootId = 'sort-button-wrapper',
  mixpanelButtonId = '',
  remoteConfigKeyName = null,
  baseFeedName = null,
  deviceWidth = 0,
  isSortMenuOpened = false,
  isFilterMenuOpened = false,
  selectedOptions = [],
  optionObject = {},
  t,
  toggleModal = () => null,
  setSelectedFeedQueryDraftOption = () => null,
}) => {
  const isMobile = deviceWidth <= breakpoint.mobile;
  const selectedOption = useMemo(() => {
    return selectedOptions[0];
  }, [selectedOptions]);
  const hasSelectedOption = selectedOption != null;
  if (!Object.keys(optionObject).length) {
    return null;
  }
  return (
    <Wrapper id={modalViewRootId}>
      <StyledSortButton
        isMenuOpened={isSortMenuOpened}
        hasSelectedOption={hasSelectedOption}
        data-element_id={mixpanelButtonId}
        onClick={event => {
          event.stopPropagation();
          if (isSortMenuOpened) {
            return toggleModal({
              modalId: 'SortMenu',
              isOpen: false,
              modalOption: {},
            });
          }
          if (isFilterMenuOpened) {
            toggleModal({
              modalId: 'FilterMenu',
              isOpen: false,
              modalOption: {},
            });
          }
          setSelectedFeedQueryDraftOption({
            remoteConfigKeyName,
            baseFeedName,
            queryType: QueryType.SORT,
            isRestore: true,
          });
          return toggleModal({
            modalId: 'SortMenu',
            isOpen: true,
            modalOption: {},
          });
        }}
      >
        <ButtonText>
          {t(
            !selectedOption || OPTION_UNSET === selectedOption
              ? 'general_sorting'
              : getTranslationKey({
                  key: selectedOption,
                }),
            {
              ns: TranslationNamespace.FEED,
            }
          )}
        </ButtonText>
        <img
          src={
            isSortMenuOpened || hasSelectedOption
              ? SortIconBlueSource
              : SortIconSource
          }
          width="20"
          height="20"
        />
        <ModalView
          id="SortMenu"
          noScrollLock={!isMobile}
          rootId={isMobile ? undefined : `#${modalViewRootId}`}
          modalViewStyle={isMobile ? undefined : MODAL_VIEW_STYLE}
          modalId={ModalId.Popup.SortingPopup}
          render={() => (
            <UniversalContainer
              filename="SortMenu"
              remoteConfigKeyName={remoteConfigKeyName}
              baseFeedName={baseFeedName}
              defaultItemI18nKey={defaultItemI18nKey}
            />
          )}
        />
      </StyledSortButton>
    </Wrapper>
  );
};

SortButton.propTypes = {
  defaultItemI18nKey: PropTypes.string,
  modalViewRootId: PropTypes.string,
  mixpanelButtonId: PropTypes.string,
  remoteConfigKeyName: PropTypes.string,
  baseFeedName: PropTypes.string,
  deviceWidth: PropTypes.number,
  isSortMenuOpened: PropTypes.bool,
  isFilterMenuOpened: PropTypes.bool,
  selectedOptions: PropTypes.array,
  optionObject: PropTypes.object,
  toggleModal: PropTypes.func,
  setSelectedFeedQueryDraftOption: PropTypes.func,
  t: PropTypes.func.isRequired,
};

const Wrapper = styled.span`
  position: relative;
`;

const StyledSortButton = styled.button.attrs(
  ({ isMenuOpened, hasSelectedOption }) => ({
    style: {
      borderColor: isMenuOpened || hasSelectedOption ? color.sky : color.white,
      color:
        isMenuOpened || hasSelectedOption
          ? textColor.secondary
          : textColor.white,
      backgroundColor: isMenuOpened ? 'rgba(96, 196, 232, 0.3)' : 'transparent',
    },
  })
)`
  ${ResetButtonStyle};
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 0px 8px;
  height: 32px;
  max-width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  transition:
    border-color 0.4s,
    color 0.4s;
  > img {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    flex: none;
    object-fit: contain;
  }
  ${media.mobile`
    height: 24px;
    max-width: 160px;
    font-size: 12px;
    > img {
      width: 20px;
      height: 20px;
    }
  `};
`;

const ButtonText = styled.div`
  ${TextEllipsis};
`;

export default withTranslation()(SortButton);
